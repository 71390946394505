<template>
	<div id="componentsChatDrawer">
		<div class="headbox">
			<div class="titlebox">
				<svg-icon class="icon" iconClass="layoutChat"></svg-icon>
				在线沟通
			</div>
			<el-button class="closeBtn" type="text" icon="el-icon-close" @click="closeChatShow()"></el-button>
		</div>
		<div class="tabbox">
			<div class="tab" :class="{'active':tabActive=='conversationList'}" @click="tabActive='conversationList'">
				<svg-icon class="icon" iconClass="chatList"></svg-icon>
				<span>会话列表</span>
			</div>
			<div class="tab" :class="{'active':tabActive=='addressBox'}" @click="tabActive='addressBox'">
				<svg-icon class="icon" iconClass="addressBook"></svg-icon>
				<span>通讯录</span>
				<el-input size="mini" v-if="tabActive=='addressBox'" 
					v-model="searchStaff" style="width: 150px;margin-left: 8px;" 
					:placeholder="guardName=='oa_partner_accounts'?'搜索名称':'搜索名称或部门'" 
					clearable @input="searchStaffInput($event)">
				</el-input>
			</div>
		</div>
		<div class="contentbox">
			<div class="chatList" v-if="tabActive=='conversationList'">
				<div class="chatInfo" @click="checkoutConversation(item)" v-for="(item,index) in allConversation" :key="index">
					<div class="avatarbox">
						<el-badge :value="item.unreadCount" :max="99" :hidden="!item.unreadCount">
							<el-image class="avatar" v-if="judgeAccountType('ORS',item.conversationID)" :src="systemInfo.logo" fit="cover"></el-image>
							<el-image class="avatar" v-else :src="item.userProfile.avatar||require('assets/icon-avatar.png')" fit="cover"></el-image>
						</el-badge>
					</div>
					<div class="infobox">
						<div class="userinfo">
							<div class="nick" v-if="judgeAccountType('ORW',item.conversationID)">{{item.userProfile.nick||`求职者${item.userProfile.userID}`}}</div>
							<div class="nick" v-else>
								<div class="admin" v-if="judgeAccountType('ORS',item.conversationID)">
									系统管理员
									<svg-icon class="icon" iconClass="auth"></svg-icon>
								</div>
								<span v-else>{{item.userProfile.nick}}</span>
							</div>
							<div class="time">{{item.lastMessage._lastTime}}</div>
						</div>
						<div class="massage" v-if="item.lastMessage.isRevoked">{{item.lastMessage.fromAccount==myInfo.im_account?'你':'对方'}}撤回一条消息</div>
						<div class="massage" v-else-if="item.lastMessage.type!= 'TIMCustomElem'">{{ item.lastMessage.messageForShow}}</div>
						<div class="massage" v-else>
							<span v-if="item.lastMessage.payload.description=='pushJob'">[职位信息]</span>
							<span v-if="item.lastMessage.payload.description=='deliverJob'">[投递职位]</span>
							<span v-if="item.lastMessage.payload.description=='interview'">[面试邀请]</span>
						</div>
					</div>
				</div>
			</div>
		
			<div class="addressBook" v-if="tabActive=='addressBox'">
				<div class="listbox" :class="{'active':item.im_account==toAccount}" v-for="item in localAdminList" :key="item.id" @click="checkAdminConversation(item)">
					<el-image class="avatar" :src="item.imgurl||require('@/assets/icon-avatar.png')" fit="cover"></el-image>
					<div class="infobox">
						<div class="name">
							{{item.name||item.username}} 
							<span v-if="item.employee">{{item.employee.department}}</span>
							<span v-if="item.is_superior">商务对接人</span>
						</div>
						<div class="phone">
							{{item.phone||'未填写联系电话'}}
						</div>
					</div>
				</div>
			</div>
			
			<conversation v-if="tabActive=='conversation'"></conversation>
		</div>
	</div>
</template>

<script>
	import {eltips} from '@/util/util.js'
	import { mapState,mapGetters } from 'vuex';
	import conversation from './conversation.vue'
	import { cloneDeep } from 'lodash';
	export default {
		components:{conversation},
		name: "componentsChatDrawer",
		data() {
			return {
				guardName:sessionStorage.getItem('guardName'),
				systemInfo:JSON.parse(sessionStorage.getItem('systemInfo')),
				myInfo:JSON.parse(sessionStorage.getItem('userinfo')),
				tabActive:'conversationList',
				adminList:[],
				localAdminList:[],
				searchStaff:'',
			};
		},
		computed: {
			...mapGetters({
				toAccount: 'toAccount',
			}),
			...mapState({
				chatShow: state => state.chatShow,
				allConversation: state => state.conversation.allConversation,
				currentConversationID: state => state.conversation.currentConversationID
			})
		},
		watch:{
			chatShow(val){
				console.log(val)
				if(!val){
					this.tabActive = 'conversationList'
					this.$store.commit('resetCurrentConversation')
				}
			},
			currentConversationID:{
				handler(val) {
					if(val){
						this.$nextTick(()=>{
							this.tabActive = 'conversation'
						})
						
					}
				},
				immediate: true
			},
		},
		async created() {
			if(!this.$store.state.sdk_ready&&sessionStorage.getItem('IMtoken')) await timLogin();
			this.getAdminList()
		},
		methods:{
			searchStaffInput(e){
				if(e){
					let arr = []
					this.adminList.forEach(item=>{
						if(item.name.includes(e)){
							arr.push(item) 
						}else if(item.username.includes(e)){
							arr.push(item) 
						}else if(item.employee&&item.employee.department.includes(e)){
							arr.push(item) 
						}
					})
					this.localAdminList = arr
				}else{
					this.localAdminList = cloneDeep(this.adminList)
				}
			},
			getAdminList(){
				let url = '/api/oa/Employee/getOnTheStaff'
				let data = { no_self:1 }
				if(this.guardName=='oa_partner_accounts'){
					url = '/api/oa/Partner/partner_all_account_list'
					data = { no_page:1 }
				} 
				this.axios.get(url,{params:data}).then(res=>{
					if(res.code==200){
						this.adminList = cloneDeep(res.data)
						this.localAdminList = cloneDeep(res.data)
						const obj = JSON.parse(sessionStorage.getItem('userinfo')).admin_data
						if(obj){
							obj.is_superior = 1
							this.adminList.unshift(obj)
							this.localAdminList.unshift(obj)
						}
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			async checkoutConversation(item){
				this.$store.commit('resetCurrentConversation')
				await this.$store.dispatch('checkoutConversation', {item:item,_this:this})
				this.tabActive = 'conversation'
			},
			async checkAdminConversation(item){
				this.$store.commit('resetCurrentConversation')
				await this.$store.dispatch('checkoutConversation', {item:{conversationID:`C2C${item.im_account}`},_this:this})
				this.tabActive = 'conversation'
			},
			judgeAccountType(type='ORW',toAccount = this.toAccount){
				if(toAccount.includes(type)){
					return true
				}else{
					return false
				}
			},
			closeChatShow(){
				this.$store.commit('changeChatShow',false)
			}
		}
	};
</script>

<style lang="less" scoped>
	#componentsChatDrawer{
		height: 100vh;
		background: #FFFFFF;
		overflow-y: auto;
		position: absolute;
		width: 360px;
		right: 0;
		top: 0;
	}
	.headbox{
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-shadow: 0px 4px 4px -4px rgba(0,0,0,.2);
		padding:0 16px;
		.titlebox{
			display: flex;
			align-items: center;
			line-height: 1;
			color: #1989fa;
			.icon{
				font-size: 18px;
				margin-right: 6px;
			}
		}
		.closeBtn{
			font-size: 18px;
			color: #333;
			transform:rotate(0deg);
			transition: transform 0.3s ease;
			&:hover{
				color: #1989fa;
				transform:rotate(180deg);
			}
		}
	}
	.tabbox{
		height: 50px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #eee;
		padding: 0 16px;
		.tab{
			margin-right: 28px;
			font-size: 14px;
			display: flex;
			align-items: center;
			cursor: pointer;
			line-height: 1;
			&:last-child{
				margin-right: 0;
			}
			.icon{
				// font-size: 16px;
				margin-right: 6px;
			}
		}
		.active{
			color: #1989fa;
		}
	}
	.contentbox{
		padding: 0 16px 16px;
		height: calc(100vh - 100px);
		overflow-y: auto;
		.chatList{
			.chatInfo{
				display: flex;
				align-items: center;
				padding: 12px 0;
				border-bottom: 1px solid #ebebeb;
				position: relative;
				cursor: pointer;
				.avatarbox{
					flex:  0 0 38px;
					width: 38px;
					height: 38px;					
					margin-right: 12px;
					position: relative;
					.avatar{
						width: 38px;
						height: 38px;
						// border-radius: 50%;
						border-radius: 4px;
					}
				}
				.infobox{
					flex: 1;
					width: 0;
					.userinfo{
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 8px;
						.nick{
							flex: 1;
							font-size: 15px;
							line-height: 1;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
						.time{
							margin-left: 8px;
							font-size: 12px;
							line-height: 1;
						}
						.admin{
							display: flex;
							align-items: center;
							line-height: 1;
							color: #1989fa;
							.icon{
								margin-left: 4px;
							}
						}
					}
					.massage{
						width: 100%;
						line-height: 1;
						font-size: 13px;
						color: #333333;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
		}
		
		.addressBook{
			width: 100%;
			.listbox{
				cursor: pointer;
				padding: 12px 0;
				border-bottom: 1px solid #ebebeb;
				&:last-child{
					margin-bottom: 0;
				}
				&:hover{
					background: rgba(25,137,250,.1);
				}
				display: flex;
				align-items: center;
				.avatar{
					flex: 0 0 38rpx;
					width: 38px;
					height: 38px;
					display: block;
					margin-right: 12px;
					// border-radius: 50%;
					border-radius: 4px;
				}
				.infobox{
					flex: 1;
					width: 0;
					.name{
						font-size: 15rpx;
						width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						margin-bottom: 8px;
						display: flex;
						justify-content: space-between;
					}
					.phone{
						font-size: 13px;
						line-height: 1;
					}
				}
			}
			.active{
				background: rgba(25,137,250,.1);
			}
		}
	}

	.notdata{
		display: flex;
		flex-direction: column;
		align-items: center;
		background: #FFFFFF;
		margin-top: 20px;
		padding: 128px 0;
		.icon{
			width:128px;
			height:128px;
		}
		.tips{
			margin-top: 12px;
			text-align: center;
		}
	}
</style>