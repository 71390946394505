// 将 contenteditable 内容处理为带换行的纯文本
function processContentEditable(content) {
	// 将 <div> 内容转换为带换行的纯文本
	const temp = document.createElement('div');
	temp.innerHTML = content;
	const textContentArray = [];
	// 遍历所有子节点
	temp.childNodes.forEach(node => {
		// 如果节点是文本节点，直接将内容加入到数组
		if (node.nodeType === Node.TEXT_NODE) {
			textContentArray.push(node.textContent);
		}
		// 如果节点是 <br> 标签，添加换行符
		else if (node.nodeName === 'BR') {
			textContentArray.push('\n');
		}
	});

	return textContentArray.join('')
}


// 检查 contenteditable 内容是否包含 img 标签，并返回对应的文件对象
function findImageFiles(content) {
	const temp = document.createElement('div');
	temp.innerHTML = content;
	const imgElements = temp.querySelectorAll('img');
	const imageFiles = [];

	imgElements.forEach(img => {
		const src = img.getAttribute('src');
		const file = getFileFromSrc(src);
		if (file) {
			imageFiles.push(file);
		}
	});

	return imageFiles;
}

// 从图片 URL 中提取文件对象
function getFileFromSrc(src) {
	const matches = src.match(/^data:(image\/\w+);base64,(.+)$/);
	if (matches && matches.length === 3) {
		const type = matches[1];
		const data = matches[2];
		const byteCharacters = atob(data);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], {
			type: type
		});
		const file = new File([blob], "image.png", {
			type: type
		});
		return file;
	}
	return null;
}

// 处理消息发送逻辑
export function getContenteditableVal(content) {
	const textContent = processContentEditable(content);
	const imageFiles = findImageFiles(content);
	// 返回纯文本和图片文件数组
	return {
		textContent,
		imageFiles
	};
}