import { Message } from 'element-ui'
import axios from 'axios'

// export const httpurl = 'https://juzi.job695.com'
export const httpurl = 'https://admin.rltsaas.com'
export const wssUrl = 'wss://juzi.job695.com/wss/'

export const mapkey = 'JKCBZ-SHJCU-LZPV3-2P7JV-LGNBZ-55BKF'
import * as echarts from 'echarts';

export function timeDifference(updateTime) {
	if (updateTime === null) return '';
	updateTime = new Date(updateTime).getTime()
	let now = new Date().getTime() 
	let second = Math.floor((now - updateTime) / (1000)) 
	let minute = Math.floor(second / 60) 
	let hour = Math.floor(minute / 60) 
	let day = Math.floor(hour / 24) 
	let month = Math.floor(day / 31) 
	let year = Math.floor(month / 12) 
	if (year > 0) {
		return year + '年前'
	} else if (month > 0) {
		return month + '月前'
	} else if (day > 0) {
		let ret = day + '天前'
		if (day >= 7 && day < 14) {
			ret = '1周前'
		} else if (day >= 14 && day < 21) {
			ret = '2周前'
		} else if (day >= 21 && day < 28) {
			ret = '3周前'
		} else if (day >= 28 && day < 31) {
			ret = '4周前'
		}
		return ret
	} else if (hour > 0) {
		return hour + '小时前'
	} else if (minute > 0) {
		return minute + '分钟前'
	} else {
		return '刚刚'
	}
	// else if (second > 0) {
	// 	return second + '秒前'
	// }
}

//下载文件
export function downloadFile(url,fileName){
	axios.get(url,{responseType: 'blob'}).then(response => {
		if(response.type=='application/json'||response.type=='text/html'){
			const reader = new FileReader()
			reader.onload = ()=>{
				let msg = '下载文件失败';
				if(response.type == 'application/json') msg = JSON.parse(reader.result).msg;
				eltips(msg,'error')
			}
			reader.readAsText(response)
			return
		}
		
		let blob = new Blob([response]);
		if (window.navigator.msSaveOrOpenBlob) {
			//兼容ie
			window.navigator.msSaveBlob(blob, fileName);
		} else {
			let downloadElement = document.createElement('a');
			let href = window.URL.createObjectURL(blob); //创建下载的链接
			downloadElement.href = href;
			downloadElement.download = fileName; //下载后文件名
			document.body.appendChild(downloadElement);
			//此写法兼容火狐
			let evt = document.createEvent("MouseEvents");
			evt.initEvent("click", false, false);
			downloadElement.dispatchEvent(evt);
			document.body.removeChild(downloadElement);
		}
		eltips('文件下载中','success')
	})
}


//下载模板方法封装
export function downloadTemplate(fileName){
	axios.get(`/excelTemplate/${fileName}?v=${new Date().getTime()}`,{responseType: 'blob'}).then(response => {
		if(response.type=='application/json'||response.type=='text/html'){
			const reader = new FileReader()
			reader.onload = ()=>{
				let msg = '下载文件失败';
				if(response.type == 'application/json') msg = JSON.parse(reader.result).msg;
				eltips(msg,'error')
			}
			reader.readAsText(response)
			return
		}
		
		let blob = new Blob([response], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
		});
		if (window.navigator.msSaveOrOpenBlob) {
			//兼容ie
			window.navigator.msSaveBlob(blob, fileName);
		} else {
			let downloadElement = document.createElement('a');
			let href = window.URL.createObjectURL(blob); //创建下载的链接
			downloadElement.href = href;
			downloadElement.download = fileName; //下载后文件名
			document.body.appendChild(downloadElement);
			//此写法兼容火狐
			let evt = document.createEvent("MouseEvents");
			evt.initEvent("click", false, false);
			downloadElement.dispatchEvent(evt);
			document.body.removeChild(downloadElement);
		}
		eltips('文件下载中','success')
	})
}

//校验密码强度
export function validatePassword(password){
	const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[a-zA-Z\d]{8,}$/;
	return pattern.test(password);
}

//校验手机号
export function isvalidPhone(phone){
	const reg = /^1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}$/
	return reg.test(phone)
}

//校验身份证
export function verifyIdCard(idCard){
	const reg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
	return reg.test(idCard)
}

//消息提示弹窗
export function eltips(text, type = 'info',duration=3000,customClass='') {
	Message({
		message: text,
		type: type,
		center: true,
		offset: 300,
		customClass:customClass,
		duration: duration,
		showClose:!duration
	})
}

//判断权限
export function hasPermissions(permission){
	let allPermissions = JSON.parse(sessionStorage.getItem('userinfo')).all_permissions
	return allPermissions.includes(permission)
}

//处理时间格式
export function setTimeStyle(time, type) {
	let newTime = new Date(time)
	let y = newTime.getFullYear()
	let m = newTime.getMonth() + 1
	let d = newTime.getDate()
	let h = newTime.getHours()
	let min = newTime.getMinutes()
	let s = newTime.getSeconds()
	if (type) {
		return `${y}-${m<10?'0'+m:m}-${d<10?'0'+d:d} ${h<10?'0'+h:h}:${min<10?'0'+min:min}:${s<10?'0'+s:s}`
	} else {
		return `${y}-${m<10?'0'+m:m}-${d<10?'0'+d:d}`
	}
}


export function parseTime(time, cFormat) {
	if (arguments.length === 0) {
		return null
	}
	const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
			time = parseInt(time)
		}
		if (typeof time === 'number' && time.toString().length === 10) {
			time = time * 1000
		}
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay()
	}
	const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
		const value = formatObj[key]
		// Note: getDay() returns 0 on Sunday
		if (key === 'a') {
			return ['日', '一', '二', '三', '四', '五', '六'][value]
		}
		return value.toString().padStart(2, '0')
	})
	return time_str
}

//复制
export function copylink(link) {
	let url = link;
	let oInput = document.createElement('input');
	oInput.setAttribute('class', 'copy_index')
	document.body.appendChild(oInput)
	oInput.value = url;
	oInput.select(); // 选择对象;
	document.execCommand("Copy"); // 执行浏览器复制命令
	document.body.removeChild(oInput)
	eltips('复制成功', 'success')
}

//截取url参数
export function getUrlKey(name, url) {
	return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url || window.location.href) || [, ""])[1].replace( /\+/g, '%20')) || null
}

//时间计算
export function formattime(time, time2) {
	if (time < time2) {
		return '时间异常'
	}
	var dateEnd = time; //获取当前时间
	var dateDiff = dateEnd - time2; //时间差的毫秒数
	var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
	var leave1 = dateDiff % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
	var hours = Math.floor(leave1 / (3600 * 1000)) //计算出小时数
	if (hours < 10) {
		hours = '0' + hours
	}
	//计算相差分钟数
	var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
	var minutes = Math.floor(leave2 / (60 * 1000)) //计算相差分钟数
	if (minutes < 10) {
		minutes = '0' + minutes
	}
	//计算相差秒数
	var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
	var seconds = Math.round(leave3 / 1000)
	if (seconds < 10) {
		seconds = '0' + seconds
	}
	var timeFn
	if (dayDiff > 1) {
		timeFn = dayDiff + "天" + hours + ":" + minutes + ":" + seconds;
	} else {
		timeFn = hours + ":" + minutes + ":" + seconds;
	}
	return timeFn;
}

//去除对象或数组中的空值('',null,undefined,[],{})
export function removeEmptyField(obj) {
	var newObj = {}
	if (typeof obj === 'string') {
		obj = JSON.parse(obj)
	}
	if (obj instanceof Array) {
		newObj = []
	}
	if (obj instanceof Object) {
		for (var attr in obj) {
			// 属性值不为'',null,undefined才加入新对象里面(去掉'',null,undefined)
			if (obj.hasOwnProperty(attr) && obj[attr] !== '' && obj[attr] !== null && obj[attr] !== undefined) {
				if (obj[attr] instanceof Object) {
					// 空数组或空对象不加入新对象(去掉[],{})
					if (JSON.stringify(obj[attr]) === '{}' || JSON.stringify(obj[attr]) === '[]') {
						continue
					}
					// 属性值为对象,则递归执行去除方法
					newObj[attr] = removeEmptyField(obj[attr])
				} else if (
					typeof obj[attr] === 'string' &&
					((obj[attr].indexOf('{') > -1 && obj[attr].indexOf('}') > -1) ||
						(obj[attr].indexOf('[') > -1 && obj[attr].indexOf(']') > -1))
				) {
					// 属性值为JSON时
					try {
						var attrObj = JSON.parse(obj[attr])
						if (attrObj instanceof Object) {
							newObj[attr] = removeEmptyField(attrObj)
						}
					} catch (e) {
						newObj[attr] = obj[attr]
					}
				} else {
					newObj[attr] = obj[attr]
				}
			}
		}
	}
	return newObj
}

export function getdate(time) {
	var now = new Date(time),
		y = now.getFullYear(),
		m = ("0" + (now.getMonth() + 1)).slice(-2),
		d = ("0" + now.getDate()).slice(-2);
	return y + "-" + m + "-" + d + " ";
}

//时间格式化
export function dateFormat(date) {
	Date.prototype.Format = function(fmt) {
		var o = {
			'M+': this.getMonth() + 1, // 月份
			'd+': this.getDate(), // 日
			'h+': this.getHours(), // 小时
			'm+': this.getMinutes(), // 分
			's+': this.getSeconds(), // 秒
			'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
			S: this.getMilliseconds() // 毫秒
		};
		if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
		for (var k in o)
			if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : (
				'00' + o[k]).substr(
				('' + o[k]).length));
		return fmt;
	};

	return (date || new Date()).Format('yyyy-MM-dd hh:mm:ss')
}

export function parseElementTime(time, pattern) {
	if (arguments.length === 0 || !time) {
		return null
	}
	const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
			time = parseInt(time)
		} else if (typeof time === 'string') {
			time = time.replace(new RegExp(/-/gm), '/');
		}
		if ((typeof time === 'number') && (time.toString().length === 10)) {
			time = time * 1000
		}
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay()
	}
	const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
		let value = formatObj[key]
		// Note: getDay() returns 0 on Sunday
		if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
		if (result.length > 0 && value < 10) {
			value = '0' + value
		}
		return value || 0
	})
	return time_str
}

//echar 折线图
export function setEchartLine(option) {
	let echar = echarts.init(document.getElementById(option.el_id))
	echar.setOption({
		tooltip: {
			trigger: 'axis',
		},
		grid: {
			left: '0',
			right: '10',
			bottom: '0',
			containLabel :true,
		},
		xAxis: {
			type: 'category',
			boundaryGap: false,
			axisTick: {
				alignWithLabel: true
			},
			data: option.x_data
		},
		yAxis: {
			type: 'value',
			minInterval: 1,
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#e9edef'],
					width: 1,
					type: 'solid'
				}
			},
		},
		series: [{
			name: '',
			type: 'line',
			smooth: true,
			stack: '数量',
			data: option.y_data,
			symbolSize: 10,
			areaStyle: {
				normal: {
					color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
						offset: 0,
						color: option.lineColor1 || 'rgba(135,154,255,1)' // 0% 处的颜色
					}, {
						offset: 0.5,
						color: option.lineColor2 || 'rgba(135,154,255,.5)' // 50% 处的颜色
					}, {
						offset: 1,
						color: option.lineColor3 || 'rgba(135,154,255,.2)' // 100% 处的颜色
					}])
				}
			},
			itemStyle: {
				normal: {
					color: option.color || '#4a67ff', //改变折线点的颜色
					lineStyle: {
						color: option.color || '#4a67ff' //改变折线颜色
					}
				}
			},
		}]
	})


	setTimeout(() => {
		window.addEventListener("resize",function(){
		    echar.resize();
		});
	}, 200)
}

// 空心圆饼
export function setEchartHollow(option) {
	console.log(option, 'option')
	let echar = echarts.init(document.getElementById(option.el_id))
	echar.setOption({
		tooltip: {
			trigger: 'item'
		},
		grid: {
			x: 0,
			y: 0
		},
		legend: {
			// x: 'center',
			y: '80%',
			textStyle: {
				color: '#5A607F',
				// fontSize: '40px',
			}
		},
		color: ['#1E5EFF', '#B539FC', '#1FD286', '#0EF7FF', '#FFC700'],
		series: [{
			type: 'pie',
			radius: '70%',
			center: ['50%', '40%'],
			avoidLabelOverlap: false,
			label: {
				show: false,
				position: 'center'
			},
			emphasis: {
				label: {
					show: false,
					fontSize: '20',
					fontWeight: 'bold'
				}
			},
			labelLine: {
				show: false
			},
			data: option.option,
		}]
	})
	setTimeout(() => {
		window.addEventListener("resize",function(){
		    echar.resize();
		});
	}, 200)
}

//圆柱
export function setEchartCylinder(option) {
	let echar = echarts.init(document.getElementById(option.el_id))
	echar.setOption({
		xAxis: {
			type: 'category',
			show: false,
		},
		yAxis: {
			type: 'value',
			show: false,
		},
		splitLine: {
			show: false
		},
		axisLine: {
			show: false
		}, //不显示坐标轴
		grid: {
			top: 20,
			left: 0,
			width: 130,
			height: 88,
			tooltip: { //---鼠标焦点放在图形上，产生的提示框
				show: true,
				trigger: 'item', //---触发类型
				textStyle: {
					color: '#ffffff',
				},
			}
		},
		color: [option.color],
		tooltip: {
			show: true, //---是否显示提示框,默认为true
		},
		series: [{
			data: [
				120,
				{
					value: 200,
					itemStyle: {
						color: '#1E92FF'
					}
				},
				150,
				80,
				70,
				110
			],
			type: 'bar',
			barWidth: 18,
			itemStyle: {
				normal: {
					//这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
					barBorderRadius: [4, 4, 0, 0]
				}
			}
		}],
	})
	setTimeout(() => {
		window.addEventListener("resize",function(){
		    echar.resize();
		});
	}, 200)
}

//圆柱二(财务)
export function setEchartCylinder2(option) {
	let echar = echarts.init(document.getElementById(option.el_id))
	echar.setOption({
		xAxis: {
			type: 'category',
			show: true,
			axisLine: { //---坐标轴 轴线
				show: false, //---是否显示
			},
			axisTick: { //--网格区域
				show: false, //---是否显示，默认false
			},
			splitArea: { //--网格区域
				show: false, //---是否显示，默认false
			},
			nameTextStyle: { //---坐标轴名称样式
				color: "#000",
				padding: [5, 0, 0, 5], //---坐标轴名称相对位置
			},

			data: option.data || ['今日', '昨日', '七日', '30日'], //内容
		},
		yAxis: {
			type: 'value',
			show: false,
		},
		splitLine: {
			show: false
		},
		axisLine: {
			show: false
		}, //不显示坐标轴
		grid: {
			top: 20,
			left: 0,
			width: option.width || 240,
			height: 88,
			tooltip: { //---鼠标焦点放在图形上，产生的提示框
				show: true,
				trigger: 'item', //---触发类型
				textStyle: {
					color: '#ffffff',
				},
			}
		},
		label: {
			show: true,
			position: 'top',
			color: '#000',
		},
		color: ['#1E92FF'],
		tooltip: {
			show: true, //---是否显示提示框,默认为true
		},
		series: [{
			data: option.option,
			type: 'bar',
			barMinHeight: 3,
			barWidth: 30,
			itemStyle: {
				normal: {
					//这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
					barBorderRadius: [4, 4, 4, 4]
				}
			}
		}],
	})
	setTimeout(() => {
		window.addEventListener("resize",function(){
		    echar.resize();
		});
	}, 200)
}

// 饼状图
export function setEchartCake(option) {
	let echar = echarts.init(document.getElementById(option.el_id))
	echar.setOption({
		legend: {
			orient: 'vertical',
			left: 'left',
			bottom: 15,
			itemGap: 24,
			itemWidth: 8,
			itemHeight: 8,
			textStyle: { //图例中文字的样式
				color: '#000',
				fontSize: 14
			},
		},
		color: ['#A4BEFF', '#FFE600', '#EDA4FF', '#1989fa', '#00ff00', '#ff6600', '#FF00FF', '#6A5ACD',
			'#F08080', '#00FF7F'
		], //饼图中各模块的颜色
		series: [{
			type: 'pie',
			radius: option.radius || '65%',
			center: option.center || ['65%', '50%'], //整个饼图在整个父元素中的位置
			data: option.option,
			minAngle: 0, //设置扇形的最小占比
			emphasis: {
				itemStyle: {
					shadowBlur: 10,
					shadowOffsetX: 0,
					shadowColor: 'rgba(0, 0, 0, 0.5)'
				}
			},
			label: {
				show: !option.seriesLabelShow || false
			}
		}]
	})

	setTimeout(() => {
		window.addEventListener("resize",function(){
		    echar.resize();
		});
	}, 200)
}

//柱状图
export function setEcharBar(option){
	let echar = echarts.init(document.getElementById(option.el_id))
	echar.setOption({
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow'
			}
		},
		xAxis: {
		    type: 'category',
		    data: option.xAxis,
			axisTick: {
				alignWithLabel: true
			}
		},
		grid: {
			left: '0',
			right: '0',
			bottom: '0',
			top:'20',
			containLabel :true,
		},
		yAxis: { type: 'value' },
		series: [{
			data: option.series,
			name: option.seriesName||'数量',
			barWidth: option.seriesBarWidth||'50%',
			type: 'bar',
			label: {
				show: true,
				position: 'top',
				color: '#000',
			},
			itemStyle:{
				normal:{
					borderRadius:option.borderRadius||[0,0,0,0],
					color: option.seriesColor||'#1989fa'
				}
			}
		}]
	})
	
	setTimeout(() => {
		window.addEventListener("resize",function(){
		    echar.resize();
		});
	}, 200)
	
	return echar
}

//看板统计
export function setMapEcharts(option) {
	let echar = echarts.init(document.getElementById(option.el))

	let echarOption = {
		title: option.title?option.title : { show:false },
		tooltip: { 
			show: true ,
			confine: true
		},
		legend: option.legend?option.legend : { show: false },
		grid: {
			left:0,
			right:0,
			top:0,
			bottom:0,
			borderWidth:'0',
			containLabel: true,
		},
		xAxis: {
			show: false
		},
		series: option.series
	}
	
	if(option.series[0].type=='bar'){
		echarOption.yAxis = {
			borderwidth:0,
			axisLabel:{
				show: true,
				interval: 0,
				textStyle:{
					color:'#FFFFFF',
					fontSize:'14'
				},
				formatter:function(value){
					var texts=value;
					if(texts.length>6){ // 具体多少字就看自己了
						texts=texts.substr(0,5)+'....';
					}
					return texts;
				}
			},
			type: 'category',
			data: option.y ? option.y.data : []
		}
	}
	
	if(option.series[0].type=='line'){
		echarOption.xAxis = option.xAxis
		echarOption.grid.top = '10'
		echarOption.grid.bottom = '10'
		echarOption.grid.right = '10'
		echarOption.yAxis = {
		    type: 'value',
			axisLabel: {
				textStyle: {
					color: '#FFFFFF',
				}
			},
		}
	}
	
	echar.setOption(echarOption)
}

//曲线图
export function setMarketCharts(option) {
	let charts = echarts.init(document.getElementById(option.id))
	charts.clear()
	let obj = {
		tooltip: {
			trigger: 'axis',
		},
		grid: {
			left: '10',
			right: '30',
			bottom: '0',
			top:'20',
			containLabel :true,
		},
		xAxis: {
			type: 'category',
			data: option.x_data,
			boundaryGap:option.boundaryGap||false,
		},
		yAxis: {
			type: 'value',
			minInterval:option.y_minInterval||1,
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#e9edef'],
					width: 1,
					type: 'solid'
				}
			}
		},
		
	}
	let series = []
	option.series.forEach(item=>{
		series.push(
			{
				name: item.name||'用户数',
				type: 'line',
				smooth: true,
				data: item.data,
				symbolSize: 8,
				showSymbol:false,
				areaStyle: {
					normal: {
						color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
							{ offset: 0, color: item.color||'#1989fa' }, 
							{ offset: 0.5, color: item.color||'#1989fa' }, 
							{ offset: 1, color: '#fff'  },
						])
					}
				},
				itemStyle: {
					normal: {
						color: item.color, //改变折线点的颜色
						lineStyle: {
							color: item.color, //改变折线颜色
						}
					}
				},
			}
		)
	})
	obj.series = series
	charts.setOption(obj)
	
	window.addEventListener("resize",function(){
	    charts.resize();
	});
	
	return charts
}

//饼状图
export function setPieCharts(option){
	let charts = echarts.init(document.getElementById(option.el_id))
	
	let obj = {
		title: {
		    text: option.title||'',
		    subtext: option.subtext||false,
		    left: option.titleCenter||'center'
		},
		tooltip: {
		    trigger: 'item'
		},
		legend: {
		    orient: 'vertical',
		    left: 'left',
		    top:option.legendTop||'top',
			bottom:'10%'
		},
		series: [{
		    name: option.seriesName||'数量',
		    type: 'pie',
		    radius: '100%',
		    data: option.series||[],
			label:{
				show:false,
			},
		    emphasis: {
				itemStyle: {
					shadowBlur: 10,
					shadowOffsetX: 0,
					shadowColor: 'rgba(0, 0, 0, 0.5)'
				}
			}
		}]
	}
	
	charts.setOption(obj)
	
	setTimeout(() => {
		window.addEventListener("resize",function(){
		    charts.resize();
		});
	}, 200)
}

import * as qiniu from 'qiniu-js'
const qiniuUrl = 'https://qiniu.job695.com/'
export function qiniuUpload(file,key,token){
	return new Promise((resolve)=> {
		const observable = qiniu.upload(file, key, token)
		const observer = {
			error(err) {
				console.error('七牛云上传失败------',err)
				resolve(false)
			},
			complete(res) {
				resolve(qiniuUrl + res.key)
			}
		}
		observable.subscribe(observer);
	})
}