export const partnerRoute = [
	{
		id:2614,
		title:'概况',
		icon:'el-icon-s-data',
		path:'/index',
		component:'apps/partner/index/index.vue',
		vue_name:'partnerIndex',router_name:'partnerIndex',layout:'1',type:1,
		props:'',
		_child:[],
	},
	{
		id:2620,
		title:'招聘动态',
		icon:'el-icon-s-promotion',
		path:'/trends',
		component:'apps/partner/station/trends.vue',
		vue_name:'partnerStationTrends',router_name:'partnerStationTrends',layout:'1',type:1,
		props:'',
		_child:[],
	},
	{
		id:9620,
		title:'我的返费',
		icon:'el-icon-s-finance',
		path:'/commission',
		component:'apps/partner/commission/list.vue',
		vue_name:'partnerCommission',router_name:'partnerCommission',layout:'1',type:1,
		props:'',
		_child:[],
	},
	{
		id:2616,
		title:'待面试管理',
		icon:'el-icon-video-camera-solid',
		path:'',
		component:'Layout',
		vue_name:'',router_name:'',layout:'1',type:1,
		props:'',
		_child:[
			{
				id:2621,
				title:'今日面试',
				icon:'',
				path:'/interview/today',
				component:'apps/partner/interview/list.vue',
				vue_name:'partnerInterviewToday',router_name:'partnerInterviewToday',layout:'1',type:1,
				props:'1',
				_child:[]
			},
			{
				id:2622,
				title:'等待面试',
				icon:'',
				path:'/interview/wait',
				component:'apps/partner/interview/list.vue',
				vue_name:'partnerInterviewWait',router_name:'partnerInterviewWait',layout:'1',type:1,
				props:'2',
				_child:[]
			},
			{
				id:2623,
				title:'过期面试',
				icon:'',
				path:'/interview/overdue',
				component:'apps/partner/interview/list.vue',
				vue_name:'partnerInterviewOverdue',router_name:'partnerInterviewOverdue',layout:'1',type:1,
				props:'3',
				_child:[]
			}
		],
	},
	{
		id:2617,
		title:'员工管理',
		icon:'el-icon-s-custom',
		path:'',
		component:'Layout',
		vue_name:'',router_name:'',layout:'1',type:1,
		props:'',
		_child:[
			{
				id:2624,
				title:'待入职管理',
				icon:'',
				path:'/user/wait',
				component:'apps/partner/user/wait.vue',
				vue_name:'partnerUserWait',router_name:'partnerUserWait',layout:'1',type:1,
				props:'',
				_child:[]
			},
			{
				id:2625,
				title:'在职管理',
				icon:'',
				path:'/user/job',
				component:'apps/partner/user/job.vue',
				vue_name:'partnerUserJob',router_name:'partnerUserJob',layout:'1',type:1,
				props:'',
				_child:[]
			},
			{
				id:2626,
				title:'待离职管理',
				icon:'',
				path:'/user/leave',
				component:'apps/partner/user/leave.vue',
				vue_name:'partnerUserLeave',router_name:'partnerUserLeave',layout:'1',type:1,
				props:'',
				_child:[]
			}
		],
	},
	{
		id:2618,
		title:'数据列表',
		icon:'el-icon-s-platform',
		path:'/user/list',
		component:'apps/partner/user/list.vue',
		vue_name:'partnerUserList',router_name:'partnerUserList',layout:'1',type:1,
		props:'',
		_child:[],
	},
	{
		id:2619,
		title:'跟进记录',
		icon:'el-icon-s-order',
		path:'/record',
		component:'apps/partner/record/list.vue',
		vue_name:'partnerRecordList',router_name:'partnerRecordList',layout:'1',type:1,
		props:'',
		_child:[],
	},
	{
		id:2627,
		title:'账号管理',
		icon:'el-icon-s-tools',
		path:'/account',
		component:'apps/partner/account/list.vue',
		vue_name:'partnerAccount',router_name:'partnerAccount',layout:'1',type:1,
		props:'',
		_child:[],
	},
]