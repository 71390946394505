<template>
	<div id="layout" style="position: relative;">
		<template>
			<template v-if="$route.meta.layout&&$route.meta.layout!=0">
				<div style="display: inline-block;vertical-align:top;" :style="{'width':chatShow?'calc(100% - 360px)':'100%'}">
					<headernav style="position: relative;z-index: 999;"></headernav>
					<div style="display: flex;">
						<asidenav class="leftbox"></asidenav>
						<div class="mainbox">
							<router-view/>
						</div>
					</div>
				</div>
				<div class="chatDrawerBox" :style="{'width':chatShow?'360px':'0','height':chatShow?'100vh':'0'}">
					<transition name="transitionChatDrawer">
						<chatDrawer class="chatDrawer" v-if="chatShow"></chatDrawer>
					</transition>
				</div>
				
				<transition name="transitionChatIcon">
					<div class="layoutChatIconbox" @click="changeChatShow()" v-show="!chatShow">
						<div :class="{'layoutChatAnimation':totalUnreadCount&&!chatShow}">
							<el-badge :value="totalUnreadCount" :max="99" :hidden="!totalUnreadCount">
							  <svg-icon class="layoutChatIcon" iconClass="layoutChat"></svg-icon>
							</el-badge>
						</div>
					</div>
				</transition>
			</template>
			
			<router-view v-else/>
		</template>
	</div>
</template>

<script>
import headernav from './components/headernav.vue'
import asidenav from './components/asidenav.vue'
import watermark from '@/util/watermark.js'
import { mapGetters,mapState } from 'vuex';
import chatDrawer from '@/components/chatDrawer/chatDrawer.vue'
export default {
	name: 'layout',
	components:{headernav,asidenav,chatDrawer},
	data() {
		return {
			blinkInterval: null,
			originalTitle: document.title
		};
	},
	computed: {
		...mapGetters({
			totalUnreadCount: 'totalUnreadCount',
		}),
		...mapState({
			chatShow: state => state.chatShow,
		})
	},
	mounted(){
		const route = this.$route;
		if(route.path!='/login'&&route.path!='/adminlogin'){
			const userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
			if(userinfo) watermark.set(userinfo.guard_name=='api'?userinfo.shop_name:userinfo.username,userinfo.guard_name=='api'?userinfo.shop_name:userinfo.phone);
		}else{
			watermark.close()
		}
	},
	watch:{
		'$route':function(route){
			if(route.path!='/login'&&route.path!='/adminlogin'&&route.path!='/partnerlogin'){
				const userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
				if(userinfo) watermark.set(userinfo.guard_name=='api'?userinfo.shop_name:userinfo.username,userinfo.guard_name=='api'?userinfo.shop_name:userinfo.phone);
			}else{
				this.stopBlinking()
				watermark.close()
			}
		},
		'$store.getters.totalUnreadCount':function(val){
			console.log('totalUnreadCount---',val)
			if(val>0){
				this.startBlinking()
			}else{
				this.stopBlinking()
			}
		}
	},
	methods:{
		changeChatShow(){
			this.$store.commit('changeChatShow',true)
		},
		startBlinking() {
			if(!this.blinkInterval){
				this.blinkInterval = setInterval(() => {
					document.title = document.title!='\u200E' ?'\u200E' : `【新消息】有新的消息待处理`;
				}, 1000);
			}
		},
		stopBlinking() {
			if (this.blinkInterval) {
				clearInterval(this.blinkInterval);
				this.blinkInterval = null;
			}
			document.title = this.originalTitle;
		}
	}
}
</script>

<style lang="less" scoped>
	.leftbox{
		flex: 0 0 200px;
		width: 200px;
		height: calc(100vh - 50px);
		overflow-y: auto;
	}
	.mainbox{
		flex: 1;
		height: calc(100vh - 50px);
		position: relative;
		overflow-y: auto;
		padding: 10px;
		overflow-x: hidden;
	}
	
	.chatDrawerBox{
		display: inline-block;
		vertical-align:top;
		position: relative;
		overflow-x: hidden;
	}
	
	.transitionChatDrawer-enter-active,.transitionChatDrawer-leave-active{
		transition: width 0.3s;
	}
	 .transitionChatDrawer-leave,.transitionChatDrawer-enter-to {
		width: 360px !important;
	}
	.transitionChatDrawer-enter, .transitionChatDrawer-leave-to {
		width: 0px !important;
	}
	
	
	
	.transitionChatIcon-enter-active,.transitionChatIcon-leave-active{
		transition: right 0.3s;
	}
	 .transitionChatIcon-leave,.transitionChatIcon-enter-to, {
		right: 0 !important;
	}
	.transitionChatIcon-enter, .transitionChatIcon-leave-to {
		right: -62px !important;
	}
	
	.layoutChatIconbox{
		position: fixed;
		bottom: 30%;
		right: 0px;
		z-index: 9999;
		background: #e8e8e8;
		border-radius: 8px 0 0 8px;
		cursor: pointer;
		padding: 12px 20px 6px 18px;
		-webkit-user-select: none; /* Chrome, Safari, Opera */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none;
		.layoutChatIcon{
			font-size: 24px;
			color: #1989fa;
		}
		&:hover{
			background: #1989fa;
			.layoutChatIcon{
				color: #FFFFFF;
			}
			.layoutChatAnimation{
				animation:none;
			}
		}
	}
	.layoutChatAnimation{
		animation: twinkle .5s infinite alternate;
	}
	@keyframes twinkle {
		0%{
			opacity: 0;
		}
		100%{
			opacity: 1;
		}
	}
</style>
