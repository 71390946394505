import router from './index.js'
import store from '../store/index.js'
import {changeroute} from './routerPermission.js'
import {timLogin} from '@/util/tim.js'
import axios from 'axios'
import {eltips} from '@/util/util.js'
import { partnerRoute } from '@/router/partnerRoute.js'

const whitelist = [
	'/login',
	// '/adminlogin',
	// '/partnerlogin'
]

function getMenu(){
	return new Promise((resolve, reject)=> {
		axios.get('/api/get_menu').then(res=>{
			if(res.code==200){
				sessionStorage.setItem('routes',JSON.stringify(res.data))
				resolve(res.data)
			}else{
				resolve([])
			}
		})
	})
}

function getPermissions(){
	return new Promise((resolve, reject)=> {
		const userinfo = JSON.parse(sessionStorage.getItem('userinfo')); 
		axios.get('/api/me',{params:{'guard_name':userinfo.guard_name}}).then(res => {
			if (res.code == 200) {
				sessionStorage.setItem('userinfo', JSON.stringify(res.data));
				resolve()
			} else {
				eltips(res.msg, 'error');
				reject()
			}
		});
	})
}

router.beforeEach(async (to, from, next) => {
	let token = sessionStorage.getItem('token') 
	let userinfo=JSON.parse(sessionStorage.getItem('userinfo'))
	let IMtoken=sessionStorage.getItem('IMtoken')
	console.log('to---------------',to)
	if(to.path=='/login'&&to.query.code&&to.query.state){
		store.commit('changeWechatCode', to.query.code)
		const guardName = to.query.state.split('=')[1]
		next({path: '/login',query:{shopId:to.query.shopId,guardName:guardName},replace:true});
	}
	if(to.path=='/adminlogin'&&to.query.code){
		store.commit('changeWechatCode', to.query.code)
		let redirectPath;
		if(to.query.state=='loginCode') redirectPath = '/adminlogin';
		next({path: redirectPath,query:{shopId:to.query.shopId},replace:true});
	}
	if(to.path=='/partnerlogin'&&to.query.code){
		store.commit('changeWechatCode', to.query.code)
		let redirectPath;
		if(to.query.state=='loginCode') redirectPath = '/partnerlogin';
		next({path: redirectPath,query:{shopId:to.query.shopId},replace:true});
	}
	
	if(!store.state.sdk_ready && IMtoken && userinfo && token){
		timLogin() 
	}
	
	if(token){
		if(store.state.routeadds==0){
			await getPermissions()
			let data
			if(sessionStorage.getItem('guardName')=='oa_partner_accounts' ){
				data = partnerRoute
				if(userinfo.pid) data.pop();
			}else{
				data = await getMenu()
			}
			router.addRoutes(changeroute(data))
			router.addRoutes([ { path: '*', redirect: '/404' } ])
			next({...to,replace:true})
		}else{
			if(to.query.code&&to.query.state=='bindWechatCode'){
				store.commit('changeBindCode', to.query.code)
				if(to.query.props){
					next({path:to.path,query:{props:to.query.props}})
				}else{
					next({path:to.path})
				}
			}else{
				next()
			}
		}
	}else{
		if(whitelist.indexOf(to.path)!=-1){
			next()
		}else{
			if(sessionStorage.getItem('guard_info')||sessionStorage.getItem('guardName')){
				let guardName = JSON.parse(sessionStorage.getItem('guard_info')).guard_name||sessionStorage.getItem('guardName')
				//跳转到指定后台登录页
				next({path: "/login",query:{shopId:sessionStorage.getItem('shopId'),guardName:guardName},replace:true});
			}else{
				let nextObj = {path: "/login",replace:true}
				if(sessionStorage.getItem('shopId')) nextObj.query = {shopId : sessionStorage.getItem('shopId')};
				next(nextObj);
			} 
		}
	}
});
